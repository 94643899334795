<!--
 * @Author: bug killer: liuyh
 * @Date: 2022-04-27 15:29:25
 * @LastEditors: bug killer: liuyh
 * @LastEditTime: 2022-06-09 17:01:39
 * @Description: file components
-->
<template>
  <div class="welcome">
    <div class="top-tips" v-if="tipsVisible">
      <span class="tips" >vDrag数字孪生低代码开发平台将于【<strong>2025年1月24日晚19:00—2025年1月28日晚19:00</strong>】进行迁移升级，迁移成功后，将第一时间通知您。</span>
      <span class="tips-right" @click="hideTips">✕</span>
    </div>
    <img class="logo" src="./assets/image/vDrag-logo-new.png" alt="" />
    <div class="components">
      <topBanner />
      <myGuide />
      <example />
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
  import Footer from './components/footer.vue'
  import topBanner from './components/banner'
  import example from './components/example'
  import myGuide from './components/guide'
  export default {
    name: 'App',
    components: {
      Footer,
      topBanner,
      myGuide,
      example,
    },
    data() {
      return {
        tipsVisible: true,
      }
    },
    mounted() {},
    methods: {
      hideTips() {
        this.tipsVisible = false
      },
      toMate() {
        window.open('https://devmate.geovisearth.com/', '_blank')
      },
    },
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #444242;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #53504f;
  }

  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-track {
    background-color: #272727;
  }
  .welcome {
    width: 100%;
  }

  .logo {
    width: 130px;
    position: absolute;
    left: 60px;
    top: 50px;
    z-index: 200;
  }
  .logo-title {
    position: absolute;
    font-family: 微软雅黑;
    color: rgb(255, 255, 255);
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    left: 120px;
    top: 55px;
  }
  .top-tips {
    background: linear-gradient(90deg, rgb(129, 122, 245) 0%, rgb(42, 130, 228) 38.89%, rgb(140, 189, 230) 71.53%, rgb(176, 85, 237) 100%);
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
    position: fixed;
    width: 100%;
    z-index: 999;
    .tips {
      display: inline;
      p {
        display: inline;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .tips-right {
      position: absolute;
      right: 260px;
      cursor: pointer;
      font-weight: 700;
    }
  }
</style>
